<template>
  <div>
    <div class="flex flex-col">
      <h2 class="font-semibold mb-4">Top up DANA</h2>
      <div class="flex gap-4">
        <div class="flex flex-col gap-4 w-2/3">
          <div class="flex flex-col p-4 gap-4 rounded-lg shadow-sidebar">
            <div>
              <label for="">Phone number</label>
              <input
                type="text"
                v-model="form.phone"
                class="w-full text-xl font-semibold py-2 px-4 text-black rounded-lg border border-gray-400"
                placeholder="example: 0812XXXXXXXX" />
            </div>
            <div class="grid grid-cols-6 gap-4">
              <button
                class="rounded-lg py-2 text-gray-600 font-semibold border border-gray-400"
                @click="form.amount = 50000">
                50 Ribu
              </button>
              <button
                class="rounded-lg py-2 text-gray-600 font-semibold border border-gray-400"
                @click="form.amount = 100000">
                100 Ribu
              </button>
              <button
                class="rounded-lg py-2 text-gray-600 font-semibold border border-gray-400"
                @click="form.amount = 200000">
                200 Ribu
              </button>
              <button
                class="rounded-lg py-2 text-gray-600 font-semibold border border-gray-400"
                @click="form.amount = 400000">
                400 Ribu
              </button>
              <button
                class="rounded-lg py-2 text-gray-600 font-semibold border border-gray-400"
                @click="form.amount = 500000">
                500 Ribu
              </button>
              <button
                class="rounded-lg py-2 text-gray-600 font-semibold border border-gray-400"
                @click="form.amount = 1000000">
                1 Juta
              </button>
            </div>
            <div>
              <label for="">Another Amount</label>
              <div class="flex">
                <span
                  class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-400 rounded-l-lg">
                  Rp
                </span>
                <input
                  type="text"
                  v-model="form.amount"
                  class="w-full text-xl font-semibold py-2 px-4 text-black rounded-r-lg border border-gray-400"
                  placeholder="0" />
              </div>
            </div>
          </div>
          <div class="flex flex-col p-4 gap-4 rounded-lg shadow-sidebar">
            <span class="text-primary font-semibold">Payment Method</span>
            <div class="flex gap-4 cursor-pointer" @click="form.vendor = 'BALANCE'">
              <div class="grid items-center">
                <img src="@/assets/logo-balance.png" />
              </div>
              <div class="grid w-full font-bold">
                <span>Balance</span>
                <span>{{ $store.state.saldo.SaldoAktif | toCurrency }}</span>
              </div>
              <div class="grid items-center">
                <div
                  class="rounded-full h-6 w-6 border-4 border-yellow p-2 grid grid-cols-1 content-center justify-items-center">
                  <div class="rounded-full h-3 w-3" :class="{ 'bg-primary': form.vendor == 'BALANCE' }"></div>
                </div>
              </div>
            </div>
            <div class="flex gap-4 cursor-pointer" @click="form.vendor = 'OTHER'">
              <div class="grid items-center">
                <img src="@/assets/logo-other-payment.png" class="w-8" />
              </div>
              <div class="grid w-full font-bold">
                <span>Other Method</span>
              </div>
              <div class="grid items-center">
                <div
                  class="rounded-full h-6 w-6 border-4 border-yellow p-2 grid grid-cols-1 content-center justify-items-center">
                  <div class="rounded-full h-3 w-3" :class="{ 'bg-primary': form.vendor == 'OTHER' }"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/3">
          <div class="flex flex-col gap-2 p-4 rounded-lg shadow-sidebar">
            <div class="grid">
              <span>Phone</span>
              <span class="font-semibold">{{ form.phone }}</span>
            </div>
            <hr class="border-gray-300 my-1" />
            <div class="flex justify-between">
              <span>Amount</span>
              <span class="font-semibold text-primary">{{ form.amount | toCurrency }}</span>
            </div>
            <hr class="border-gray-300 my-1" />
            <div class="flex justify-between font-bold">
              <span>Total</span>
              <span class="text-primary">{{ form.amount | toCurrency }}</span>
            </div>
            <hr class="border-gray-300 my-1" />
            <div class="flex">
              <button @click="createInquiry" class="bg-primary p-2 font-bold rounded-lg text-white text-xl w-full">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-inquiry
      :dialog="dialogInquiry"
      :data="inquiry"
      @closeDialog="dialogInquiry = false"
      @convertBalance="convertBalance"></modal-inquiry>
    <modal-pin ref="modalPin"></modal-pin>
  </div>
</template>

<script>
export default {
  name: "Topup",
  components: {
    ModalInquiry: () => import("./components/ModalInquiry.vue"),
    ModalPin: () => import("@/components/layout/ModalPin.vue"),
  },
  data() {
    return {
      dialogInquiry: false,
      form: {
        uuid: "",
        phone: "",
        amount: "",
        vendor: "",
      },
      inquiry: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Balance", routeName: "TripwePay" },
      { text: "Topup", routeName: "Topup" },
    ]);
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.banks[this.selectedBank].virtual_account);
      this.$toasted.global.success("Virtual account number has been copied to clipboard");
    },
    copyToClipboardTxt(txt) {
      navigator.clipboard.writeText(txt);
      this.$toasted.global.success("Copied to clipboard");
    },
    async createInquiry() {
      try {
        this.$store.commit("setOverlayLoading", true);
        this.form.uuid = this.$uuidv4();
        const res = await this.$http.post(`${this.$apiTripweWeb}/dana/e-wallet/inquiry-account`, this.form);
        if (res.data.status != 200) {
          throw res.data.message;
        }
        this.inquiry = res.data.data;
        this.dialogInquiry = true;
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async convertBalance() {
      const pin = await this.$refs.modalPin.open({
        button: "OK",
      });
      if (pin == null) return false

      try {
        this.$store.commit("setOverlayLoading", true);
        this.form.uuid = this.$uuidv4();
        const res = await this.$http.post(`${this.$apiTripweWeb}/dana/e-wallet/convert-balance`, {
          reference_no: this.inquiry.reference_no,
          pin: pin
        });
        if (res.data.status != 200) {
          throw res.data.message;
        }
        this.$router.push({name: 'Dana'});
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
  },
};
</script>
